import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Container,
  Modal,
  Spinner,
} from "react-bootstrap";
import {Link} from "gatsby";

import { init, send } from "emailjs-com";

import Header from "../components/header.jsx";
import Footer from "../components/footer.jsx";
import Seo from "../components/seo";
import { MARGIN_TOP } from "../utils/constants.js";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      show: false,
      validated: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    init(process.env.GATSBY_EMAIL_JS_USER_ID);
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  }

  handleClose = () => {
    this.state.success
      ? this.setState({
        ...this.state,
        show: false,
        validated: false,
      })
      : this.setState({ ...this.state, show: false });
  };
  handleShow = (success) =>
    this.setState({ ...this.state, show: true, success, loading: false });

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, validated: true });
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      this.setState({ ...this.state, loading: true });
      let res = await send(
        process.env.GATSBY_EMAIL_JS_SERVICE_ID,
        process.env.GATSBY_EMAIL_JS_TEMPLATE_ID,
        this.state,
        process.env.GATSBY_EMAIL_JS_USER_ID
      );
      if (res.status === 200) {
        // Mail sent successfully
        this.setState({
          name: "",
          email: "",
          phone: "",
          message: "",
          validated: false,
        });
        this.handleShow(true);
        form.reset();
      } else {
        // Error
        this.handleShow(false);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Seo
          title="Contact me | mg98photographer"
          description="Vuoi prenotare uno shooting o desideri maggiori informazioni sulle mie foto? Contattami."
          url="https://www.mg98photographer.it/contacts"
        >
          {/*FontAwesome  icons*/}
          <link
            href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
            rel="stylesheet"
          />
        </Seo>
        <Header />
        <Container style={{ textAlign: "center", marginTop: `${MARGIN_TOP}px` }}>
        <div className="mb-3" style={{textAlign: "left"}}>
          <Link style={{ color: "black" }} to="/"><i className="fas fa-home" /> Home</Link>
        </div>
          <h2>CONTACT</h2>
          <p>
            Vuoi prenotare uno shooting o desideri maggiori informazioni?
            <br />
            Compila il form!
          </p>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
          >
            <Row>
              <Col>
                <Form.Control
                  name="name"
                  minLength="1"
                  placeholder="Name"
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={this.handleChange}
                  required
                />
              </Col>
            </Row>
            <Form.Control
              className="mt-2 mb-2"
              type="tel"
              minLength="10"
              maxLength="10"
              name="phone"
              placeholder="Phone number"
              onChange={this.handleChange}
              required
            />
            <Form.Control
              className="mb-2"
              as="textarea"
              minLength="5"
              name="message"
              placeholder="Message"
              onChange={this.handleChange}
              required
            />
            <Button variant="dark" type="submit" disabled={this.state.loading}>
              {this.state.loading ? (
                <React.Fragment>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </React.Fragment>
              ) : (
                  "SEND"
                )}
            </Button>
          </Form>
        </Container>
        <Footer />
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.success ? "Messaggio inviato" : "Errore"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.success
              ? "A breve riceverai una mia risposta ;)"
              : "Si è verificato un errore durante l'invio del messaggio, per favore riprova oppure contattami sui social!"}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={this.handleClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Contacts;
